import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getEventType } from '../../event_types/api/event_types';
import { ArrowLeft, ArrowRight, CalendarEvent, Clock, Floppy, Globe, List, ListNested, Person, Save, Save2 } from 'react-bootstrap-icons';
import DaysCalendar from '../../calendar/components/DaysCalendar/DaysCalendar';
import { storeEvent } from '../../calendar/api/events';
import { useNavigate } from 'react-router-dom';
import Button from '../../common/components/Button';
import styles from './ScheduleEvent.module.css';
import { Link } from 'react-router-dom';

function ScheduleEvent() {

    const { id } = useParams();
    const [selectedDay, setSelectedDay] = useState({});
    const [selectedTime, setSelectedTime] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [senderName, setSenderName] = useState('');
    const [senderEmail, setSenderEmail] = useState('');

    const navigate = useNavigate();
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentMonthName = currentDate.toLocaleString('default', { month: 'long' });
    const currentYear = currentDate.getFullYear();
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [eventType, setEventType] = useState([]);

    useEffect(() => {
        const loadEventTypes = async () => {
            try {
                const fetchedEventType = await getEventType(id);
                setEventType(fetchedEventType);
                setPageLoading(false);
            } catch (error) {
                console.error('Error fetching schedule:', error);
            }
        };

        loadEventTypes();
    }, []);

    const handleMonthChange = (direction) => {
        if (direction === 'prev') {
            if (selectedMonth === 1) {
                setSelectedMonth(12);
                setSelectedYear(selectedYear - 1);
            } else {
                setSelectedMonth(selectedMonth - 1);
            }
        } else if (direction === 'next') {
            if (selectedMonth === 12) {
                setSelectedMonth(1);
                setSelectedYear(selectedYear + 1);
            } else {
                setSelectedMonth(selectedMonth + 1);
            }
        }
    };

    const getLocalTimeZone = () => {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    };

    const timeZone = getLocalTimeZone();

    const createDatetime = () => {
        const day = selectedDay?.date || 1;
        const month = selectedMonth || currentMonth;
        const year = selectedYear || currentYear;

        // console.log("Day:", day);
        // console.log("Month:", month);
        // console.log("Year:", year);
        // console.log("Selected Time:", selectedTime);

        const dateString = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        console.log("Date String:", dateString);

        const datetimeString = selectedTime ? `${dateString}T${selectedTime}` : `${dateString}T00:00:00`;
        console.log("Datetime String:", datetimeString);

        const datetime = new Date(datetimeString);

        if (isNaN(datetime.getTime())) {
            console.error("Invalid date or time");
            return null;
        }

        return datetime;
    }

    const addMinutes = (date, minutes) => {
        return new Date(date.getTime() + minutes * 60000);
    }

    const handleCreateEvent = async () => {

        setIsLoading(true);

        const startDatetime = createDatetime();
        if (!startDatetime) {
            console.error("Start datetime is invalid. Cannot create event.");
            return;
        }

        const endDatetime = addMinutes(startDatetime, 30);

        // Format the datetime manually to avoid timezone issues
        const formatDateTime = (datetime) => {
            const year = datetime.getFullYear();
            const month = (datetime.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
            const day = datetime.getDate().toString().padStart(2, '0');
            const hours = datetime.getHours().toString().padStart(2, '0');
            const minutes = datetime.getMinutes().toString().padStart(2, '0');
            const seconds = datetime.getSeconds().toString().padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const formattedStartDatetime = formatDateTime(startDatetime);
        const formattedEndDatetime = formatDateTime(endDatetime);

        const event = {
            "title": eventType.name,
            "description": eventType.description,
            "start_time": formattedStartDatetime,
            "end_time": formattedEndDatetime,
            "sender_name": senderName,
            "sender_email": senderEmail,
            "recipient": eventType.user,
        }

        await storeEvent(event);

        navigate('/event_types/new/success');

    }

    const isTimeAvailable = (time) => {
        const dayOfWeek = selectedDay?.dayOfWeek;
        const schedule = JSON.parse(eventType?.user?.schedule?.days);

        const scheduleForDay = schedule.find(day => day?.day?.toLowerCase().includes(dayOfWeek?.toLowerCase()));

        if (!scheduleForDay || !scheduleForDay.available) {
            return false;
        }

        const [startHour, startMinute] = scheduleForDay.start_time.split(':').map(Number);
        const [endHour, endMinute] = scheduleForDay.end_time.split(':').map(Number);
        const [selectedHour, selectedMinute] = time.split(':').map(Number);

        const startTime = new Date();
        startTime.setHours(startHour, startMinute, 0, 0);

        const endTime = new Date();
        endTime.setHours(endHour, endMinute, 0, 0);

        const selectedTime = new Date();
        selectedTime.setHours(selectedHour, selectedMinute, 0, 0);

        if (!(selectedTime >= startTime && selectedTime <= endTime)) {
            return false;
        }

        const events = eventType.user.events;

        for (const event of events) {

            const eventStartTime = getTimeFromTimestamp(event.start_time);
            const eventEndTime = getTimeFromTimestamp(event.end_time);

            if(eventStartTime == time){
                return false;
            }

        }

        return true;
    };

    const getTimeFromTimestamp = (timestamp) => {
        const timePart = timestamp.split(' ')[1]; // Extracts "12:30:00" from "2024-09-13 12:30:00"
        const [hours, minutes] = timePart.split(':').map(Number); // Convert "12:30:00" to [12, 30]
        return `${hours}:${minutes}`;
    };


    if (pageLoading) {
        return (
            <div style={{ background: 'var(--color-background)' }}>
                <div style={{ background: 'var(--color-background)' }}>
                    <div className='container d-flex flex-column align-items-center justify-content-center'
                        style={{ minHeight: '100vh' }}>
                        <div className='spinner-border txt-primary' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{ background: 'var(--color-background)' }}>
            <div className='container d-flex flex-column align-items-center justify-content-center'
                style={{ minHeight: '100vh' }}>

                <div className="card w-100">
                    <div className="row">
                        <div className="col-md-4 d-flex flex-column justify-content-between">

                            <div>
                                <p>
                                    {eventType?.user?.name}
                                </p>
                                <h3 className='bold'>{eventType.name}</h3>

                                <p className='text-muted d-flex align-items-center mt-4'>
                                    <Clock className='me-2' />
                                    {eventType.duration} mins
                                </p>

                                <p className='text-muted d-flex align-items-center'>
                                    <List className='me-2' />
                                    {eventType.description}
                                </p>

                                <p className='txt-primary d-flex align-items-center'>
                                    <Globe className='me-2' />
                                    Timezone {timeZone}
                                </p>

                            </div>


                            <div>

                                <div className="mb-3">
                                    <label className='mb-1 ps-1'>Name</label>
                                    <input type="text" value={senderName} onChange={(e) => { setSenderName(e.target.value) }} className='form-control medium py-3' placeholder='Your name' />
                                </div>
                                <div className="mb-3">
                                    <label className='mb-1 ps-1'>Email</label>
                                    <input type="email" value={senderEmail} onChange={(e) => { setSenderEmail(e.target.value) }} className='form-control medium py-3' placeholder='Your email' />
                                </div>

                                {selectedDay && selectedTime && senderName && senderEmail && (
                                    <Button
                                        loading={isLoading}
                                        variant='primary'
                                        size='sm'
                                        onClick={handleCreateEvent}>
                                        <span className='px-3 py-2 bold'>
                                            <CalendarEvent className='me-2' />
                                            Schedule Event
                                        </span>
                                    </Button>
                                )}
                            </div>

                        </div>
                        <div className="col-md-5" style={{ borderLeft: '1px solid #f3f3f3' }}>

                            <h5 className='bold mb-4 mt-3 ps-3'>Select a Date</h5>


                            <div className='d-flex justify-content-center align-items-center py-3'>
                                <div className='hover' onClick={() => handleMonthChange('prev')}>
                                    <ArrowLeft className='pointer text-muted' />
                                </div>
                                <h5 className='mb-0 px-2 text-muted medium' style={{ fontSize: '12pt' }}>
                                    {new Date(selectedYear, selectedMonth - 1).toLocaleString('default', { month: 'long' })} {selectedYear}
                                </h5>
                                <div className='hover' onClick={() => handleMonthChange('next')}>
                                    <ArrowRight className='pointer text-muted' />
                                </div>
                            </div>

                            <DaysCalendar
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                selectedMonth={selectedMonth}
                                selectedYear={selectedYear}
                                currentMonthName={currentMonthName}
                                eventType={eventType}
                            />
                        </div>

                        <div className="col-md-3" style={{ borderLeft: '1px solid #f3f3f3' }}>

                            <h5 className='bold mb-4 mt-3'>Select Time</h5>


                            {selectedDay?.dayOfWeek && (
                                <div className="d-flex mt-4 flex-column align-items-center pe-3" style={{ overflow: 'auto', maxHeight: 430 }}>
                                    {['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'].map(time => (
                                        <button
                                            key={time}
                                            className={`btn w-100 mb-3 py-3 ${styles.timeBtn} ${selectedTime === time ? styles.active : ''}`}
                                            onClick={() => setSelectedTime(time)}
                                            disabled={!isTimeAvailable(time)}
                                        >
                                            {time.replace(':00', ':00 h').replace(':30', ':30 h')}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default ScheduleEvent
