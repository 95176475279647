import React from 'react'
import { setFocusedTask } from '../../common/utils/focus';
import { useFocusMode } from '../../common/contexts/FocusContext';
import { Eye, EyeFill } from 'react-bootstrap-icons';

function FocusButton({ task }) {

    const { showFocusMode, setShowFocusMode } = useFocusMode();

    const handleOpenFocusMode = (event, task) => {
        setFocusedTask(task);
        // window.ipcRenderer.send('open-focus-mode', { task: task });
        event.stopPropagation();
        setShowFocusMode(true);
    };

    return (
        <button className='btn btn-basic bg-gray border shadow-sm hover medium d-flex align-items-center px-3 w-100' onClick={(event) => handleOpenFocusMode(event, task)}>
            <EyeFill className='txt-primary me-2' />
            Focus
        </button>
    )
}

export default FocusButton