import React, { useState, useEffect, useRef } from 'react'
import { getFocusedTask } from '../../common/utils/focus'
import { truncateString } from '../../common/utils/format_utils'
import RecordCommentFromFocus from './RecordCommentFromFocus'
import { saveTimeTracker, stopTimeTracker, continueTimeTracker } from '../../common/api/time_tracker'
import { ArrowClockwise, ArrowLeft, Pause, PlayFill } from 'react-bootstrap-icons'
import Draggable from 'react-draggable'
import { useNavigate } from 'react-router-dom'
import { useFocusMode } from '../../common/contexts/FocusContext'

function FocusWindowWeb() {
    const [time, setTime] = useState(0)
    const [running, setRunning] = useState(false)
    const [task, setTask] = useState([])
    const [existingTimeTracker, setExistingTimeTracker] = useState([])
    const { setShowFocusMode } = useFocusMode()

    const navigate = useNavigate()
    const startTimestampRef = useRef(null)
    const timeElapsedBeforeStartRef = useRef(0)

    const focusedTask = getFocusedTask()

    useEffect(() => {
        setTask(focusedTask)
    }, [focusedTask])

    // Update the time based on actual elapsed time
    useEffect(() => {
        let timerInterval

        if (running) {
            startTimestampRef.current = Date.now() - timeElapsedBeforeStartRef.current
            timerInterval = setInterval(() => {
                const elapsedTime = Math.floor((Date.now() - startTimestampRef.current) / 1000)
                setTime(elapsedTime)
            }, 1000)
        }

        return () => clearInterval(timerInterval)
    }, [running])

    const handleCreateTimeTracker = async () => {
        const timeTracker = {
            task_id: task.id
        }
        const createdTimeTracker = await saveTimeTracker(timeTracker)
        setExistingTimeTracker(createdTimeTracker)
    }

    const handleStopTimeTracker = async () => {
        const timeTracker = {
            time_spent: time
        }
        try {
            await stopTimeTracker(timeTracker, existingTimeTracker.id)
        } catch (error) {
            console.log(error)
        }
    }

    const handleContinueTimeTracker = async () => {
        try {
            await continueTimeTracker(existingTimeTracker.id)
        } catch (error) {
            console.log(error)
        }
    }

    const handleReturnToTasks = () => {
        setTime(0)
        setRunning(false)
        handleStopTimeTracker()
        setExistingTimeTracker([])
        setShowFocusMode(false)
    }

    const startStopwatch = () => {
        setRunning(true)
        if (existingTimeTracker.length === 0) {
            handleCreateTimeTracker()
        } else {
            handleContinueTimeTracker()
        }
    }

    const pauseStopwatch = () => {
        setRunning(false)
        timeElapsedBeforeStartRef.current = time
        handleStopTimeTracker()
    }

    const resetStopwatch = () => {
        setTime(0)
        setRunning(false)
        timeElapsedBeforeStartRef.current = 0
        handleStopTimeTracker()
        setExistingTimeTracker([])
    }

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600)
        const minutes = Math.floor((time % 3600) / 60)
        const seconds = time % 60
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }

    const handleLinkClick = (url) => {
        navigate(url)
    }

    return (
        <Draggable handle=".focus-window-wrapper">
            <div className='focus-window-wrapper rounded-lg px-2 pb-2 position-fixed'
                style={{ height: 200, width: 380, '-webkit-app-region': 'drag', zIndex: 9999999999, top: '0.8rem', left: '0.8rem' }}>
                <div className='bg-white rounded-lg px-4 pb-4 pt-3 h-100' style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>

                    <div className='d-flex align-items-center pb-3'>
                        <h4 className='mb-0 mt-1 pe-2 bold'>{formatTime(time)}</h4>
                        {running ? (
                            <button className='btn p-0' onClick={pauseStopwatch}><Pause className='h3 mb-0 mx-1' /></button>
                        ) : (
                            <button className='btn p-0' onClick={startStopwatch}><PlayFill className='mx-1 h3 m-0 txt-primary' /></button>
                        )}
                        <button className='btn p-0' onClick={resetStopwatch}><ArrowClockwise className='mx-1' /></button>
                    </div>

                    <a onClick={() => { handleLinkClick(`/tasks#${task.id}`) }} className='pointer bold txt-primary medium'>
                        {task.subject}
                    </a>
                    <p className='small text-muted'>
                        {task?.description?.length && truncateString(task.description, 100)}
                    </p>

                    <div className='d-flex align-items-center justify-content-between'>
                        <button className='btn px-0 small' onClick={handleReturnToTasks}>
                            <ArrowLeft className='me-2' />
                            Return to app
                        </button>
                        <div className='d-flex align-items-center'>
                            <div className='px-2'>
                                <RecordCommentFromFocus task={task} setTask={setTask} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Draggable>
    )
}

export default FocusWindowWeb
